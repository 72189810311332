import React, { useEffect, useState } from "react";
import { NavBar } from "../../components/NavBar";
import { TitleBar } from "../../components/TitleBar";
import { Alert, Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { EditableForm } from "../../components/Everify/EditableForm";
import { useLocation } from "react-router-dom";
import LoaderModal from "../../components/LoaderModal";
import { NonEditableForm } from "../../components/Everify/NonEditableForm";
import axios from "axios";
import { API } from "../../controllers/API";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ConfirmDetailsForm from "../../components/Everify/ConfirmDetailsForm";
import { checkDuplicates, handleSubmitEverify, photoMatch, retrievePhoto } from "../../utils/Everify";
import ConfirmPhotoForm from "../../components/Everify/ConfirmPhotoForm";
import { ResultBlock } from "../../components/Everify/ResultBlock";
import { ConfirmFAN } from "../../components/Everify/ConfirmFAN";
import { StartEverifyModal } from "../../components/Everify/StartEverifyModal";
import { ConfirmFinalNonconfirmation } from "../../components/Everify/ConfirmFinalNonconfirmation";
import ScanAndUploadForm from "../../components/Everify/ScanAndUpload";


export const EverifyDetails = ()=>{
    const {state} = useLocation();
    const [edit, setEdit] = useState(false)
    const [update, setUpdate] = useState(false)
    const token = Cookies.get('token');
    const [formFields, setFormFields] = useState(null);
    const [loading, setLoading] = useState(true);
    const [confirmDetails, setConfirmDetails] = useState([])
    const [photoMatchDoc, setPhotoMatchDoc] = useState({document_photo:''})
    const [photoMatchStatus, setPhotoMatchStatus] = useState(null)
    const [openEverify, setOpenEverify] = useState(false);
    const [everifyData, setEverifyData] = useState({});
    const [gettingData, setGettingData] = useState(false);
    const [sendingSubmitting, setSendingSubmitting] = useState(false)

    const {id, i9Id, caseNumber, sOne, sTwo, location} = state
    let navigate = useNavigate();

useEffect(()=>{
const getData = async ()=>{

    let resp = await axios.post(API + '/everify/authentication/check-case', {caseNumber: caseNumber})
    console.log(resp.data)
setFormFields(resp.data.sendBack);

if(resp.data.sendBack.case_status === 'UNCONFIRMED_DATA'){
  let checkDataResp = await axios.post(API + '/everify/authentication/confirm-case-fields', {caseNumber:caseNumber});
  console.log(checkDataResp.data.result.case_fields)

  if (checkDataResp.data.result.case_fields) {
    // Compare the array of strings with formFields and create confirmDetails
    const matchingFields = Object.entries(resp.data.sendBack)
      .filter(([property]) => checkDataResp.data.result.case_fields.includes(property))
      .map(([property, value]) => ({
        field_name: property,
        value: value /* You can set the value based on your requirements */,
      }));

    setConfirmDetails(matchingFields);
    console.log(matchingFields)
    setLoading(false)
  }

  
} 

else if (resp.data.sendBack.case_status === 'PHOTO_MATCH'){
 console.log('photo')
retrievePhoto(caseNumber)
    .then(photoData => {
        console.log('Retrieved photo data:', photoData);
        // Do something with the retrieved photo data
        setPhotoMatchDoc(photoData)
        setLoading(false)
    })
    .catch(error => console.error('Error:', error));


}

else if (resp.data.sendBack.case_status === 'PENDING_REFERRAL'){
  setLoading(false)
}

else {
  setLoading(false)
}







}
getData()

},[update])
const handleSubmit = async () =>{
    
  let resp = await axios.post(API + '/everify/authentication/submit-case',{caseNumber:formFields.case_number})
    if(resp.data.submitted){
        window.alert('The case has been submitted. Check back occasionally review the case status.')
        navigate('/everify/pending')
    } else {
        window.alert('Something went wrong. Please try again.')
    }
}

const handleConfirmDetails = async ()=>{
  setLoading(true)
  let resp = await axios.post(API + '/everify/authentication/confirm-case',{caseNumber:formFields.case_number, case_fields:confirmDetails})
  console.log(resp.data);

  window.location.reload();
}


const sendPhotoMatch = async ()=>{
setLoading(true)
  photoMatch(caseNumber, photoMatchStatus)
  .then(photoData => {
      console.log('Retrieved photo match:', photoData);
      // Do something with the retrieved photo data
    
    window.location.reload();
  })
  .catch(error => console.error('Error:', error));
}

if(loading){
    return <LoaderModal open={loading}/>
} else {

    return(
    <Box>
      <NavBar/>
            <TitleBar
            NavigationTitle="Back"
            To={-1}
            Title="E-Verify"
            />

            <Box sx={{padding:5}} >
             
                <Paper sx={{ padding:5, width:'100%'}}>
                <Box 
                sx={{display: formFields.case_status === 'SCAN_AND_UPLOAD' ? 'block':'none'}}
                >
                      <ScanAndUploadForm
                      caseNumber={formFields.case_number}
                      />
                    </Box>

                  <Box sx={{display: formFields.case_status === 'FINAL_NONCONFIRMATION' || formFields.case_status === 'CLOSE_CASE_AND_RESUBMIT' ? 'block':'none'}}>
                    <ConfirmFinalNonconfirmation
                    caseNumber={formFields.case_number}
                    status={formFields.case_status}
                    />
                  </Box>
                
                    <Box sx={{paddingBottom:4}}>
                        <Typography sx={{fontSize:18}}><b>REVIEW EMPLOYEE INFORMATION</b></Typography>
                        <Box sx={{display: formFields.case_status === 'PENDING_REFERRAL' ? 'block':'none'}}>
                      <ConfirmFAN
                      name={formFields.first_name}
                      caseNumber={formFields.case_number}
                      employee_decision_date={formFields?.employee_decision_date}
                      />
                    </Box>

                    <Box sx={{display: formFields.case_status === 'CLOSED' && formFields.case_eligibility_statement !== 'EMPLOYMENT_AUTHORIZED'  ? 'block':'none'}}>
                      <Alert severity="warning">
                        <Typography onClick={()=>setOpenEverify(true)} sx={{ display:'inline', cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} >Click here to restart E-Verify</Typography>
                      </Alert>
                    </Box>
                    {/* <Typography sx={{display:formFields.case_eligibility_statement === 'EMPLOYMENT_AUTHORIZED' ?'none':'block'}} variant="p">Review the information in case information below. When you are ready click "SUBMIT" at the bottom of the form.</Typography> */}
                   
               
                    </Box>
                   

                    <Box sx={{display:photoMatchDoc.document_photo ?'block':'none', marginBottom:5}}>
                    <Alert sx={{ border:'solid orange 1px'}} severity="warning">
                    <Box sx={{marginBottom:2}}>
                    <Typography sx={{fontSize:16, marginBottom:5}} variant="p"><b>Does the photo displayed match the photo displayed on {formFields.first_name} {formFields.last_name}'s Document?</b></Typography>
                    </Box>
                      <ConfirmPhotoForm
                      documentImage={photoMatchDoc?.document_photo}
                      selectedValue={photoMatchStatus}
                      setSelectedValue={setPhotoMatchStatus}
                      />
                      
                      </Alert>
                      <Box sx={{textAlign:'right', marginTop:2}}>
                        <Button variant="contained" onClick={sendPhotoMatch}>Submit</Button>
                      </Box>
                    </Box>

                   
                    
                    <Box sx={{display:confirmDetails.length > 0 ? 'block': 'none'}}>
                    
                    <Alert sx={{ border:'solid orange 1px'}} severity="warning">
                    <Grid container>
                      <Typography>
                      {`Please confirm the details below:`}
                      </Typography>

                      <Grid  item sm={12}>
                        
                        {confirmDetails.map((i)=>{
                          return (
                            <Box sx={{marginTop:2}}>
                            <Typography><b>{i.field_name.replace(/_/g,' ').toUpperCase()}</b></Typography>
                            <Typography>{i.value}</Typography>

                            <ConfirmDetailsForm
                            confirmDetails={confirmDetails}
                            updateField={i.field_name}
                            updateValue={i.value}
                            setConfirmDetails={setConfirmDetails}
                            />
                            </Box>
                          )
                        })}
                    
                      </Grid>
                    </Grid>
                    </Alert>
                    <Box sx={{textAlign:'end', marginTop:2}}>
                      <Button onClick={handleConfirmDetails} variant="contained">Confirm Details</Button>
                    </Box>
                    </Box>
                    <Box sx={{display:formFields.case_eligibility_statement === 'EMPLOYMENT_AUTHORIZED' ?'block':'none', marginBottom:2}}>
                  <ResultBlock/>
                  </Box>
                    <Grid container>
                 <Grid sx={{marginBottom:2}} item xs={12} sm={6}>
                 <Typography>
              <b>CASE NUMBER</b>
            </Typography>

            <Typography>
              {formFields.case_number}
            </Typography>    
                
                </Grid>      
                <Grid sx={{marginBottom:2}} item xs={12} sm={6}>
                 <Typography>
              <b>CASE STATUS</b>
            </Typography>

            <Typography sx={{color: formFields.case_eligibility_statement === 'EMPLOYMENT_AUTHORIZED' ? 'green':'orange'}}>
            <b>{formFields.case_status}</b>
            </Typography>    
                
                </Grid>  
                {edit &&(
              <EditableForm initialData={formFields}/>

                )} 

                 {edit === false &&(
              <NonEditableForm initialData={formFields}/>

                )}     

              </Grid>
              </Paper>
              <Box sx={{padding:2, textAlign:'end'}}>
              {/* <Button sx={{marginRight:2}} onClick={()=>setEdit(!edit)} variant="contained">{edit ? 'Save': 'Edit'} Information</Button> */}

                <Button sx={{display:formFields.case_status === 'DRAFT'? 'inline':'none'}} onClick={handleSubmit} variant="contained">Submit</Button>
              </Box>

              <StartEverifyModal
          
            onSubmit={async () =>{ 
              setGettingData(true)
              setEverifyData(await checkDuplicates(sOne, sTwo, sOne.listType, token, setGettingData, i9Id, ()=>window.history.back(), location))}}
            onClose={()=>{setOpenEverify(false); setEverifyData([]); setGettingData(false)}}
            gettingData={gettingData}
            initialData={everifyData}
            open={openEverify}
            onSubmitEverify={()=>{setSendingSubmitting(true); handleSubmitEverify(everifyData, token, i9Id, ()=>window.history.back(),()=>setSendingSubmitting(false)); }}
            sendingSubmitting={sendingSubmitting}
            showSkip={true}
            />
            </Box>
    
    </Box>
    )
}
}