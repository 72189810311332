// Import necessary hooks and components from Stripe and Material UI at the top
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Card, CardContent, CardActions, Box, Paper, Grid, Divider, Container, IconButton } from '@mui/material';
import { API } from '../controllers/API';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NavBar } from '../components/NavBar';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoaderModal from '../components/LoaderModal';
import { TitleBar } from '../components/TitleBar';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import ToolTipButton from '../components/TooltipButton';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { formatDate, formatSubDate } from '../utils/Global-Functions';



const AccountSettings = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [update, setUpdate] = useState(false)
  const [user,setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState(null);

  let navigate = useNavigate();

  function downloadCSV(data) {
    const headers = Object.keys(data[0]).filter(header => header !== 'id'); // Exclude 'id' from headers
    const csvRows = [headers.join(',')];

    data.forEach(row => {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${user.companyInformation.companyName} log.csv`;
    link.click();
    URL.revokeObjectURL(url);
}


  useEffect(() => {
    // Fetch payment method details



    const fetchUserData = async () => {
      try {
        // Check if the token cookie exists before making the request
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        const response = await axios.post(API+ '/auth/refresh-user', { token });
        const refreshedUser = response.data.user;
        setUser(refreshedUser)
        setCustomerId(refreshedUser.stripeCustomerId)
        fetchCustomerPaymentMethod(refreshedUser.stripeCustomerId);
        fetchSubscriptionStatus(refreshedUser.stripeSubscriptionId)
        
        // Update the user data in the state
     
        console.log(refreshedUser)
       
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };
    function downloadCSV(data) {
    console.log(data)
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    data.forEach(row => {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'output.csv';
    link.click();
    URL.revokeObjectURL(url);
}

    const fetchSubscriptionStatus = async (stripeSubscriptionId) => {
      try {
          const response = await axios.get(API+`/admin/subscription-status/${stripeSubscriptionId}`);
          const data = await response.data;
          console.log(data);
          setStatus(data); // Update the subscription status
          setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
          console.error('Error fetching subscription status:', error);
      }
  };

    const fetchCustomerPaymentMethod = async (stripeCustomerId) => {
      try {
        const response = await axios.post(API + `/auth/customer-payment-method/${stripeCustomerId}`);
    
        // Set the payment method details in your state
        setPaymentMethod(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching customer payment method:', error);
      }
    };

    fetchUserData();


  }, [update]);

  const fetchSubscriptionStatus = async (stripeSubscriptionId) => {
    try {
      const response = await axios.get(API + `/admin/subscription-status/${stripeSubscriptionId}`);
      const data = response.data;
      console.log(data);
      setStatus(data); // Update the subscription status
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };
  
  

  const handleCancelSubscription = async (subscriptionId) => {
 
    try {
        setLoading(true);

       

        // Make a POST request to cancel the subscription
        const response = await axios.post(API+ `/admin/cancel-subscription/${subscriptionId}`);
        alert(response.data.message);
    } catch (error) {
      alert('An error occurred while canceling the subscription.');
        console.error('Error canceling subscription:', error);
    } finally {
        setLoading(false);
        setUpdate(!update);
    }
};

const handleRenewSubscription = async (subscriptionId) => {
  try {
    setLoading(true);

    // Make a POST request to renew the subscription
    const response = await axios.post(`${API}/admin/renew-subscription/${subscriptionId}`);
    alert(response.data.message);

    // Optionally, refresh user data to reflect changes
    setUpdate(!update);
  } catch (error) {
    alert(
      error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while renewing the subscription.'
    );
    console.error('Error renewing subscription:', error);
  } finally {
    setLoading(false);
  }
};


  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleUpdatePaymentMethod = async () => {
    if (!stripe || !elements) {
      console.log('Stripe has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
    } else {
      // Call your backend to attach this new payment method to the customer
      // and optionally set it as the default payment method
      try {
        await axios.post(API + `/auth/update-payment-method`, { paymentMethodId: paymentMethod.id, stripeCustomerId: customerId });
        setShowUpdateForm(false); // Close the update form
        // Fetch updated payment method details or refresh customer data
      } catch (backendError) {
        console.error('Error updating payment method:', backendError);
      }
    }
  };

  const handleRemovePaymentMethod = async () => {
    let conf = window.confirm('Are you sure you would like to do this?')
    if(conf){
      
    try {
      // Assuming you are sending a DELETE request to your backend
      await axios.post(`${API}/auth/payment-method/${paymentMethod.id}`, {
        headers: {
          'Content-Type': 'application/json',
          // Include any necessary headers, such as authorization tokens
        },
      });
      // Reset the local state to reflect that the payment method has been removed
      setPaymentMethod(null);

      alert('Payment method removed successfully.');
    } catch (error) {
      console.error('Error removing payment method:', error.response.data);
      alert(`Error removing payment method: ${error.response.data.error}`);
    }


  }
  };

  const handleAddPaymentMethod = async () => {
    if (!stripe || !elements) {
      console.log("Stripe hasn't loaded yet.");
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
  
    if (error) {
      console.error('[error]', error);
    } else {
      // Send paymentMethod.id and the customer's Stripe ID to your backend
      try {
        const response = await axios.post(`${API}/auth/add-payment-method`, {
          stripeCustomerId: customerId, // Ensure you have the customer's Stripe ID
          paymentMethodId: paymentMethod.id,
        });
        // Display success message
        alert('Payment method added successfully');
        setUpdate(!update);
        setShowAddPaymentForm(false)
        } catch (error) {
        console.error('Error adding payment method:', error.response.data);
      }
    }
  };
  
if(loading){
  return (<LoaderModal open={true}/>)
} else{
  
  // JSX remains mostly unchanged, add a button to open the update form...
  return (
    <Box>
       <Box>
      <NavBar/>
      <TitleBar Title={'Account Settings'}
      To={-1}
      DisplayButton={true}
      ButtonTitle={'Download Account Log'}
      onClick={()=>downloadCSV(user.log)}
      NavigationTitle={'Back'}

      />
<Box sx={{ margin:3, padding:2 }} component={Paper}>
<Typography sx={{fontSize:22}} variant='button'><b>User Information</b></Typography>
     <hr/>
      <Grid container>
       <Grid sx={{marginBottom: 2}} item sm={6} xs={12}>
        <Typography><b>First Name</b></Typography>
        <Typography>{user.firstName}</Typography>
       </Grid>

       <Grid sx={{marginBottom: 2}} item sm={6} xs={12}>
        <Typography><b>Last Name</b></Typography>
        <Typography>{user.lastName}</Typography>
       </Grid>

       <Grid sx={{marginBottom: 2}} item sm={6} xs={12}>
        <Typography><b>Email</b></Typography>
        <Typography>{user.email}</Typography>
       </Grid>
  
      </Grid>
      <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Company Information</b></Typography>
     <hr/>
     <Grid container>
       <Grid  item sm={12} xs={12}>
        <Typography><b>Employer Category</b></Typography>
        <Typography>{user.employerCategory}</Typography>
        <br/>
       </Grid>

       <Grid  item sm={12} xs={12}>
        <Typography><b>Company Name</b></Typography>
        <Typography>{user.companyInformation.companyName}</Typography>
        <br/>
       </Grid>

       <Grid item xs={12} sm={12}>
                  <Typography>
                    <b>Doing Business As(DBA) Name</b>
                  </Typography>
                  <Typography>{user.companyInformation.dba}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Employer Identification Number (EIN)</b>
                  </Typography>
                  <Typography>{user.companyInformation.eid}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Unique Entity Identifier (UEI)</b>
                  </Typography>
                  <Typography>{user.companyInformation.uei}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>DUNS Number</b>
                  </Typography>
                  <Typography>{user.companyInformation.dunsNo}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography><b>Total Number of Employees</b></Typography>
                  <Typography>{user.companyInformation.totalNumberOfEmployees}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>3-Digit NAICS Code</b>
                  </Typography>
                 <Typography>{user.companyInformation.naicsCode}</Typography>
                  <br />
                </Grid>
    

      </Grid>
      <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Company Addresses</b></Typography>
     <hr/>
      <Grid container>
      <Grid item xs={12} sm={10}>
                <Typography variant="h6">
                  <b>Physical Address</b>
                </Typography>
                
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography>
                  <b>Street Address</b>
                </Typography>
                <Typography>{user.physicalAddress.streetAddress}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography>
                <b>Suite/Other</b>
                </Typography>
                <Typography>{user.physicalAddress.suite}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>City</b>
                </Typography>
                <Typography>{user.physicalAddress.city}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>State</b>
                </Typography>
                <Typography>{user.physicalAddress.state}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ZIP Code</b>
                </Typography>
                <Typography>{user.physicalAddress.zipCode}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>County</b>
                </Typography>
                <Typography>{user.physicalAddress.county}</Typography>
                <br />
              </Grid>
      </Grid>

        {user.useMailingAddress && (
       <Grid container>
          <Grid item xs={12} sm={10}>
          <Typography variant="h6">
            <b>Mailing Address</b>
          </Typography>
          <Divider/>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography>
            <b>Street Address</b>
          </Typography>
          <Typography>{user.mailingAddress.streetAddress}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography>
          <b>Suite/Other</b>
          </Typography>
          <Typography>{user.mailingAddress.suite}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            <b>City</b>
          </Typography>
          <Typography>{user.mailingAddress.city}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            <b>State</b>
          </Typography>
          <Typography>{user.mailingAddress.state}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            <b>ZIP Code</b>
          </Typography>
          <Typography>{user.mailingAddress.zipCode}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
                <Typography>
                  <b>County</b>
                </Typography>
                <Typography>{user.mailingAddress.county}</Typography>
                <br />
              </Grid>
</Grid>
        )}
      <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Hiring Sites</b></Typography>
     <hr/>
     <Grid container>
     <Grid item xs={12} sm={6}>
                <Typography>
                  <b>Total Number of Hiring Sites</b>
                </Typography>
                <Typography>{user.hiringSites.length + 1}</Typography>
                <br />
              </Grid>
              </Grid>

          
     <Box>

     </Box>

     <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Membership Information</b></Typography>
     <hr/>
     <Grid container>
     <Grid item xs={12} sm={12}>
     <div>
     <Typography sx={{fontSize:18}} variant='button'><b>Membership:</b></Typography>
     <Typography variant='p'>{user.membership} Membership</Typography>
    <Box>
            <Typography sx={{fontSize:18, }} variant='button'><b>Status:</b> <span style={{color: status.status === 'orange'? 'red':status.status === 'active' ? 'green':'tomato'}}>{status.status.toUpperCase()}</span></Typography>
            <Box>

</Box>
  {status.status === 'active' && !status.cancelationDate && (
    <Typography sx={{ fontSize: 18 }} variant="button">
      <b>Renewal Date:</b> {formatDate(status.renewalDate)}
    </Typography>
  )}
  {status.status === 'active' && status.cancelationDate && (
    <Typography sx={{ fontSize: 18 }} variant="button">
      <b>Subscription Ends On:</b> {formatDate(status.cancelationDate)}
    </Typography>
  )}
  {status.status === 'canceled' && (
    <Typography sx={{ fontSize: 18 }} variant="button">
      <b>Subscription Ended On:</b> {formatDate(status.cancelationDate)}
    </Typography>
  )}
  {status.status === 'past_due' && (
    <Typography sx={{ fontSize: 18 }} variant="button">
      <b>Subscription Ended On:</b> {formatDate(status.endDate)}
    </Typography>
  )}
  {status.status === 'unpaid' && (
    <Typography sx={{ fontSize: 18 }} variant="button">
      <b>Subscription Ended On:</b> {formatDate(status.endDate)}
    </Typography>
  )}
  {status.status === 'incomplete_expired' && (
    <Typography sx={{ fontSize: 18 }} variant="button">
      <b>Subscription Expired On:</b> {formatDate(status.endDate)}
    </Typography>
  )}
  {/* Other code */}
</Box>

        </div>
           
     <hr/>

     </Grid>
      <Grid item xs={12} sm={12}>
      <hr/>
      <Typography sx={{fontSize:18}} variant='button'><b>Payment Information</b></Typography>
    
     <Box maxWidth={300}>

     <CardContent> 

      
        {paymentMethod ? (
          <>
            <Cards
            preview
        number={`000000000000${paymentMethod.last4}`}
        expiry={`${paymentMethod.exp_month}${paymentMethod.exp_year}`}
        
        name={'______________'}
        issuer={paymentMethod.brand}
    
      />
          </>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No payment method added.
          </Typography>
          
        )}
        {showAddPaymentForm && (
      <div>
               <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        p: 2,
        borderRadius: 1,
        mt: 2,
        mb: 3,
      }}
    >
      <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' }, }, invalid: { color: '#9e2146' }, } }} />
    </Box>
        <Button onClick={handleAddPaymentMethod}>Save Payment Method</Button>
      </div>
    )}
      </CardContent>
      <CardActions>
        <Box sx={{textAlign:'right'}}>
      {paymentMethod && (
        <>
        <ToolTipButton
      Title="Update Payment Method"
      onClick={() => setShowUpdateForm(true)}
     
      />
      <Button
      onClick={() => setShowUpdateForm(true)}
      >
      <CachedIcon htmlColor='dodgerblue'/> {'\u00A0' + 'Update'}
      </Button>
     
          <Dialog  open={showUpdateForm} onClose={() => setShowUpdateForm(false)}>
            <DialogTitle>Update Payment Method</DialogTitle>
            <DialogContent sx={{width:500}}>
            <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        p: 2,
        borderRadius: 1,
        mt: 2,
        mb: 3,
      }}
    >
      <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' }, }, invalid: { color: '#9e2146' }, } }} />
    </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowUpdateForm(false)}>Cancel</Button>
              <Button onClick={handleUpdatePaymentMethod}>Update</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {paymentMethod && (
       
       <Button
        onClick={handleRemovePaymentMethod}
        sx={{color:'tomato'}}
        >
        <DeleteIcon htmlColor='tomato'/> {'\u00A0' + 'Delete'}
        </Button>
       
)}


{/* Other component code */}
{!paymentMethod && (
      <Button variant="outlined" sx={{display: showAddPaymentForm ? 'none':'block'}} onClick={() => setShowAddPaymentForm(true)}>
        Add Payment Method
      </Button>
    )}
    </Box>
        {/* Implement and place a button or form here for adding/updating payment methods */}
      </CardActions>
     </Box>
      </Grid>
     </Grid>
</Box>

   
    </Box>
      
    </Box>
  );

}
};

export default AccountSettings;